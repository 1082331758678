<template>
  <div class="m-product-add-to-cart">
    <SfAddToCart
      v-model="qty"
      class="sf-add-to-cart__button m-product-add-to-cart__button"
      :class="{'m-product-add-to-cart__button-checking-stock': stock.isLoading}"
    >
      <template #add-to-cart-btn>
        <AAddToCart
          :qty="qty"
          :products="products"
          :disabled="isAddToCartDisabled"
          @change="changeHandler"
          :is-compact="isCompact"
          @price-update="handlePriceUpdate"
          :caption="caption"
          :should-open-cart="shouldOpenCart"
          :gifts="gifts"
        />
      </template>
      <template #quantity-select-input>
        <div />
      </template>
    </SfAddToCart>
    <SfAlert
      v-if="alert"
      class="add-to-cart-error"
      :message="alert.message"
      :type="alert.type"
    >
      <template #icon>
        <span />
      </template>
    </SfAlert>
  </div>
</template>
<script>

import { onlineHelper } from '@vue-storefront/core/helpers';
import SfAlert from '@storefront-ui/vue/src/components/molecules/SfAlert/SfAlert.vue';
import SfAddToCart from '@storefront-ui/vue/src/components/molecules/SfAddToCart/SfAddToCart.vue';

import AAddToCart from '~/theme/components/atoms/a-add-to-cart';
import flatMap from 'lodash-es/flatMap';
import { isInStock } from '@vue-storefront/core/modules/catalog/helpers/stock'
import { getGroupedProductEnabledChildren } from '@vue-storefront/core/modules/catalog/helpers/associatedProducts'
import { isServer } from '@vue-storefront/core/helpers'

export default {
  name: 'MProductAddToCart',
  components: {
    SfAddToCart,
    SfAlert,
    AAddToCart
  },
  data () {
    return {
      qty: 1,
      qtyValidationError: ''
    };
  },
  props: {
    products: {
      type: Array,
      required: true,
      default: () => []
    },
    stock: {
      type: Object,
      default: () => ({})
    },
    isCompact: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isOutOfStock: {
      type: Boolean,
      default: false
    },
    caption: {
      type: String,
      default: null
    },
    shouldOpenCart: {
      type: Boolean,
      default: true
    },
    gifts: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    isOnline () {
      return onlineHelper.isOnline;
    },
    isAddToCartDisabled () {
      return !!this.qtyValidationError || this.stock.isLoading || !this.isAvailable || this.disabled || this.isOutOfStock || this.isNotEnoughInStock
    },
    isAvailable () {
      return this.products.length >= 1 ||
        (this.isOnline || !!this.stock.max || !this.stock.manageQuantity || !this.isSimpleOrConfigurable)
    },
    isSimpleOrConfigurable () {
      return ['simple', 'configurable'].includes(
        this.products[0].type_id
      );
    },
    isNotEnoughInStock () {
      return this.productsLowOnStock.length !== 0
    },
    productsLowOnStock () {
      return flatMap(this.products, product => {
        let products = [product]
        if (product.type_id === 'grouped') {
          products = getGroupedProductEnabledChildren(product)
        }
        if (product.type_id === 'bundle' || product.type_id === 'configurable') return []
        return flatMap(products, linkedProduct => {
          const isEnoughInStock = linkedProduct.isGift || linkedProduct.qty <= linkedProduct.stock.qty
          if (isEnoughInStock) return []
          return {
            name: linkedProduct.name,
            stockQuantity: linkedProduct.stock.qty
          }
        })
      })
    },
    alert () {
      if (isServer) return false
      if (this.isOutOfStock || this.isNotEnoughInStock) {
        return {
          type: 'danger',
          message: this.$t('This product is out of stock.')
        }
      }
      if (this.qtyValidationError) {
        return {
          type: 'danger',
          message: this.qtyValidationError
        }
      }
      if (!this.isAvailable) {
        return {
          type: 'danger',
          message: this.$t('Selected variant is out of stock')
        }
      }
      return false
    }
  },
  methods: {
    handleQuantityValidationError (error) {
      this.qtyValidationError = error
    },
    handlePriceUpdate (prices) {
      this.$emit('price-update', prices)
    },
    changeHandler () {
      this.$emit('change')
    }
  }
};
</script>

<style lang="scss" scoped>
.add-to-cart-error {
  --alert-message-margin: 0;
  --alert-font-size: calc(var(--font-size--sm) * 1.1);
  margin: var(--spacer-sm) 0 0;
  white-space: pre-wrap;
}

.m-product-add-to-cart {
  &__button {
    --button-height: 57px;
    &-checking-stock {
      ::v-deep {
        .sf-button {
          background: var(--c-primary);
        }
      }
    }
  }
}
</style>
