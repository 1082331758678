<template>
  <SfButton
    class="a-add-to-cart sf-button--full-width"
    :disabled="isProductDisabled"
    @click="addToCart"
  >
    <div v-show="!showVerificationIcon && !btnClicked" class="button-wrapper">
      <div v-if="caption" class="button-content-wrapper--caption">
        {{ caption }}
      </div>
      <div v-else class="button-content-wrapper">
        <div class="price-cart-icon">
          <AIcon
            size="1.6rem"
            icon="cart"
            color="white"
            class="icon"
            v-if="!isCompact"
          />
        </div>
        <div class="current-price">
          {{ priceOnButton ? formatPrice(priceOnButton) : this.$t('Sold Out') }}
        </div>
        <div class="strikethrough-price">
          <div v-if="hasStrikethroughPrice">
            {{ priceOnButton ? formatPrice(regularPrice) : '' }}
          </div>
        </div>
      </div>
    </div>
    <div v-show="showsSpinner" class="button-content-wrapper button-content-wrapper--spinner">
      <ASpinner :size="20.75" />
    </div>
    <div v-show="showVerificationIcon && !isCartBusy" class="button-content-wrapper button-content-wrapper--spinner">
      <SfIcon
        icon="check"
        size="2rem"
        color="white"
      />
    </div>
  </SfButton>
</template>

<script>
import { mapGetters } from 'vuex';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import { formatProductMessages } from '@vue-storefront/core/filters/product-messages';
import { notifications, prepareProductsToAdd } from '@vue-storefront/core/modules/cart/helpers';
import { getProductPrice, formatPrice } from '~/theme/helpers';
import AIcon from '~/theme/components/atoms/a-icon.vue';
import ASpinner from '~/theme/components/atoms/a-spinner';
import TrackingMixin from '~/theme/mixins/tracking/tracking.ts'
import { Microcart } from '@vue-storefront/core/modules/cart/components/Microcart.ts'

import { getGroupedProductEnabledChildren } from '@vue-storefront/core/modules/catalog/helpers/associatedProducts'
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue'

export default {
  name: 'AAddToCart',
  components: {
    SfButton,
    AIcon,
    ASpinner,
    SfIcon
  },
  mixins: [TrackingMixin, Microcart],
  props: {
    products: {
      required: true,
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    qty: {
      type: Number,
      default: 1
    },
    isCompact: {
      type: Boolean,
      default: false
    },
    caption: {
      type: String,
      default: null
    },
    shouldOpenCart: {
      type: Boolean,
      default: true
    },
    gifts: {
      type: Array,
      default: () => ([])
    }
  },
  mounted () {
    this.emitPriceUpdate()
    this.initTrackingScripts()
  },
  data () {
    return {
      btnClicked: false,
      showVerificationIcon: false
    }
  },
  computed: {
    ...mapGetters({
      isCartBusy: 'cart/getCartProcessingStatus'
    }),
    priceOnButton () {
      if (this.isParentConfigurable && this.products[0].qty === 1) {
        return this.finalPrice
      }
      return this.specialPrice || this.finalPrice || this.regularPrice
    },
    isProductDisabled () {
      return (
        this.disabled ||
        this.errorMessages !== '' ||
        this.isCartBusy ||
        this.priceOnButton === 0 // TODO: remove if store will support products with price equals 0
      );
    },
    errorMessages () {
      return this.products.map(product => formatProductMessages(product.errors)).join('')
    },
    hasStrikethroughPrice () {
      return this.priceOnButton < this.regularPrice
    },
    regularPricesList () {
      return this.flatProductList.map(product => getProductPrice(product, {}, true).regular)
    },
    specialPricesList () {
      return this.flatProductList.map(product => {
        const price = getProductPrice(product, {}, true)
        if (price.special !== null) return price.special
        return price.regular
      })
    },
    regularPrice () {
      return this.regularPricesList.reduce((sum, count) => count ? sum + count : sum, 0)
    },
    specialPrice () {
      return this.specialPricesList
        .reduce((sum, count) => count ? sum + count : sum, 0)
    },
    price () {
      return this.flatProductList.reduce((sum, product) => sum + (product.price || 0) * product.qty, 0)
    },
    finalPrice () {
      return this.flatProductList.reduce((sum, product) => sum + (product.final_price || 0) * product.qty, 0)
    },
    flatProductList () {
      return this.products.flatMap(product => {
        if (product.type_id === 'configurable') {
          if (!product.is_configured) return product
          const matchedChild = product.configurable_children.find(child =>
            product.configurable_options.every(option =>
              product[option.attribute_code] === child[option.attribute_code]
            )
          )
          if (!matchedChild) return []
          matchedChild.qty = product.qty
          matchedChild.parentSku = product.parentSku
          return matchedChild
        }
        if (product.type_id === 'grouped') {
          return getGroupedProductEnabledChildren(product)
        }
        return product
      })
    },
    productsToBeAddedToCart () {
      return this.products
        .flatMap(this.extractGroupedProductChildren)
        .filter(product => product && !product.isGift)
    },
    showsSpinner () {
      return (this.isCartBusy && this.btnClicked)
    },
    isParentConfigurable () {
      return this.products[0].type_id === 'configurable'
    }
  },
  methods: {
    formatPrice (price) {
      return formatPrice(price)
    },
    emitPriceUpdate () {
      this.$emit('price-update', {
        priceOnButton: this.priceOnButton,
        strikeThroughPrice: this.regularPrice
      })
    },
    extractGroupedProductChildren (product) {
      if (product.type_id === 'grouped') {
        return product.product_links
          .map(link => link.product)
          .map(product => {
            return {
              type_id: 'grouped', // wihtout type_id as grouped for children of grouped products, gifts don't work
              ...product
            }
          })
          .filter(link => link?.qty > 0)
      }
      return product
    },
    async addToCart () {
      this.btnClicked = true
      try {
        const totalPrice = this.totals.find(item => item.code === 'grand_total')?.value

        this.productsToBeAddedToCart.forEach(product => {
          this.handleAddingToCart({
            cartProducts: this.productsInCart,
            totalPrice: totalPrice,
            addedProduct: product,
            checkoutUrl: 'checkout'
          })
        })
        const productsToAdd = this.productsToBeAddedToCart
          .map(prepareProductsToAdd)
          .map(productWrapper => productWrapper[0])
        const diffLog = await this.$store.dispatch('cart/addItems', {
          productsToAdd,
          gifts: this.gifts.map(gift => ({ gift_id: gift.id, rule_id: gift.ruleId, product_option: gift.product_option }))
        });
        this.$emit('change')
        document.body.classList.remove('bodylock');

        /**
         * Try applying coupon from the page URL
         * @type {string}
         */
        const applyCartCoupon = localStorage.getItem('shop/cart/applyCartCoupon');
        if (applyCartCoupon) {
          await this.$store.dispatch('cart/applyCoupon', {
            couponCode: applyCartCoupon
          });
          localStorage.removeItem('shop/cart/applyCartCoupon');
        }

        this.showVerificationIcon = true
        this.$store.dispatch('ui/toggleProductQuickSwitcher', false)
        this.btnClicked = false
        setTimeout(() => {
          this.showVerificationIcon = false
          this.$store.dispatch('ui/toggleMicrocart', true)
        }, 500);
      } catch (message) {
        this.$store.dispatch(
          'notification/spawnNotification',
          notifications.createNotification({ type: 'danger', message }), { root: true }
        );
      }
    }
  },
  watch: {
    priceOnButton () {
      this.emitPriceUpdate()
    },
    regularPrice () {
      this.emitPriceUpdate()
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.add-to-cart-top {
  .a-add-to-cart {
    @include for-mobile {
      display: none;
    }
  }
}
.a-add-to-cart {
  --button-padding: var(--spacer-sm) var(--spacer-base);
  --loader-spinner-stroke: currentColor;
  font-size: var(--font-size--base);
  @include for-desktop {
    font-size: var(--font-size--lg);
  }
}
.icon {
  margin-right: 0;
  ::v-deep {
    path {
      stroke: transparent;
      fill: var(--c-light-variant);
      stroke-width: 2px;
    }
  }
}

.button-wrapper {
  width: 100%;
}
.button-content-wrapper {
  --button-background: var(--c-true-black);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--caption {
    @extend .button-content-wrapper;
    justify-content: center;
  }

  &--spinner {
    justify-content: center;
  }

  & > div {
    width: 33%;

    &:nth-child(1) {
      text-align: left;
    }

    &:nth-child(2) {
      text-align: center;
    }

    &:nth-child(3) {
      text-decoration: line-through;
      text-align: right;
    }
  }
}
</style>
